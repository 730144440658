import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-bottom: 2rem;
`;

export const SubContainer = styled.div`
    display: grid;
    width: 123rem;
    grid-template-columns: 1fr 1fr 1fr;
    position: relative;
    align-items: flex-start;
    justify-content: center;
    justify-items: center;
    margin-top: 9rem;
    height: 100%;

    @media (${({ theme }) => theme.media.largeLaptopScreen}) {
        display: grid;
        width: 82rem;
        grid-template-columns: 1fr 1fr;
    }

    @media (${({ theme }) => theme.media.smallLaptopScreen}) { 
        width: 70rem;
    }

    @media (${({ theme }) => theme.media.tabletScreen}) {
        width: 62rem;
    }

    @media (${({ theme }) => theme.media.smallTabletScreen}) {
        grid-template-columns: 1fr;
        width: 30rem;
        margin-left: 1rem;
    }
`;
