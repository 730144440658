'use client';

import * as Styled from './styled';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const BarInformation = () => {
  return (
    <>
      <Styled.Container>
        <Styled.Title>IP Dev Blog</Styled.Title>
        <Styled.Description>
          Learn <FontAwesomeIcon icon={faArrowRight} />
          Teach <FontAwesomeIcon icon={faArrowRight} />
          Grow
        </Styled.Description>
      </Styled.Container>
    </>
  );
};
