import * as Styled from './styled';

export interface TagProps {
  tags: string[];
}

export const Tags = ({ tags }: TagProps) => {
  return (
    <Styled.Container>
      <strong>Tags:</strong>
      {tags.map((tag, index) => (
        <span key={index}>
          #{tag}
          {tags.length - 1 != index ? ' ' : ''}
        </span>
      ))}
    </Styled.Container>
  );
};
