'use client';

import * as Styled from './styled';
import { PostDetail, PostProps } from '../PostDetail';

export interface PostListProps {
  posts: PostProps[];
}

export const PostList = ({ posts }: PostListProps) => {
  return (
    <Styled.Container>
      <Styled.SubContainer>
        {posts.map((post, index) => (
          <PostDetail key={index} {...post} />
        ))}
      </Styled.SubContainer>
    </Styled.Container>
  );
};
