import * as Styled from './styled';

export interface PostedDateProps {
  postedDate: string;
}

export const PostedDate = ({ postedDate }: PostedDateProps) => {
  return (
    <Styled.Container>
      <strong>Posted:</strong> {postedDate}
    </Styled.Container>
  );
};
