import styled from 'styled-components';

export const Container = styled.h2`
    font-weight: 700;
    font-size: 2rem;
    padding: 0 1rem;
    margin-bottom: 1rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;

    @media (${({ theme }) => theme.media.tabletScreen}) {
        font-size: 1.7rem;
    }
`;
