import styled from 'styled-components';

export const Container = styled.p`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 1.2rem;

    strong {
      margin-right: .2rem;
    }

    @media (${({ theme }) => theme.media.tabletScreen}) {
    font-size: 1.1rem;
  }
`;
