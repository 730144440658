import styled from 'styled-components';
import { Container as Title } from '../Heading/styled';
import { Container as Tags } from '../Tags/styled';

export const PostSummary = styled.article`
    display: grid;
    position: relative;
    align-items: center;
    justify-content: center;
    justify-items: center;
    width: 40rem;
    border-radius: 1rem;
    box-shadow: .2rem .2rem .5rem 0 ${({ theme }) => theme.colors.lightBlue};
    margin-bottom: 2rem;

    img {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        justify-items: center;
        width: 38rem;
        margin: 1rem 0;
        border-radius: 1rem;
        height: 19rem;
    }

    button {
        margin-bottom: 1rem;
    }

    ${Title} {
        font-size: 1.8rem;
        margin-bottom: .5rem;
    }

    @media (${({ theme }) => theme.media.smallLaptopScreen}) {
        width: 34rem;

        img {
            width: 32rem;
            height: 16rem;
        }
    }

    @media (${({ theme }) => theme.media.tabletScreen}) {
        width: 30rem;

        img {
            width: 28rem;
            height: 14rem;
        }
    }

    @media (${({ theme }) => theme.media.smallTabletScreen}) {
        width: 28rem;
        margin-bottom: 1rem;

        img {
            width: 26rem;
            height: 13rem;
        }
    }
`;

export const Information = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin-bottom: 1rem;

    @media (${({ theme }) => theme.media.smallLaptopScreen}) {
        display: grid;
        justify-content: center;
        justify-items: center;
        
        ${Tags} {
            padding: .5rem;
        }
    }
`;
