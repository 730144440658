import styled from 'styled-components';

export const Container = styled.article`
    display: grid;
    position: relative;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    height: 30rem;
    top: 7rem;
    left: 0;
    right: 0;
    background: ${({ theme }) => theme.colors.blueDark};

    svg {
        width: 2rem;
        margin: 0 1rem;
    }

    h1, p {
        color: ${({ theme }) => theme.colors.light};
    }
`;

export const Title = styled.h1`
    font-size: 5rem;
    margin-bottom: 1rem;
`;

export const Description = styled.p`
    font-weight: 700;
    font-size: 2rem;
`;
