import * as Styled from './styled';

interface HeadingProps {
  title: string | null;
}

const Heading: React.FC<HeadingProps> = ({ title }) => {
  return <Styled.Container>{title}</Styled.Container>;
};

export default Heading;
